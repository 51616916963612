Apt.fn.make('locator', {
	/**
	 * Initialize module
	 *
	 * @param {$} el
	 * @param {Object} [options]
	 * @returns {Object}
	 */
	init: function(el, options) {
		var scope = this;

		scope.$private.setup(el, options);

		return scope;
	},

	/**
	 * Collapse results
	 */
	collapse: function() {
		this.$private.collapse();
	},

	/**
	 * Highlight the input element
	 */
	highlight: function() {
		LS.validate.highlight(this.$private.$input, true);
	},

	/**
	 * Blur the locator input
	 */
	blur: function() {
		this.$private.$input.blur();
	},

	/**
	 * Get selected value
	 *
	 * @returns {Object}
	 */
	val: function() {
		return this.$private.app.$get('value');
	},

	/**
	 * Get input text
	 *
	 * @returns {String}
	 */
	text: function() {
		return this.$private.$input[0].value;
	},

	/**
	 * Set selected value
	 *
	 * @param {(Boolean|Object)} [value]
	 */
	set: function(value) {
		var scope = this,
			priv = scope.$private;

		if (! priv.app) {
			return;
		}

		if (value === true) {
			priv.setDefault();

			return;
		}

		if (value && value.name) {
			priv.val = priv.default = LS.location.heading(value, true);

			priv.app.$set('value', value);

			priv.$input.val(priv.val);

			return;
		}

		scope.clear(true);
	},

	/**
	 * Clear input values
	 *
	 * @param {Boolean} [empty=false]
	 */
	clear: function(empty) {
		var priv = this.$private;

		priv.default = priv.val = null;

		priv.app.$drop('value');

		if (empty) {
			priv.$input.val('');
		}
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		var priv = this.$private;

		priv.collapse();

		LS.util.reset(priv.uid, priv, 'app', true);
	}
}, {
	/**
	 * Generate target elements and create application
	 *
	 * @param {$} el
	 * @param {Object} [options]
	 */
	setup: function(el, options) {
		var scope = this,
			conf = $.extend({
				default: false
			}, options);

		scope.uid = LS.util.uid();

		conf.key = conf.id ?
			'id' : (conf.key || 'slug');

		scope.conf = conf;

		scope.$target = $(el);
		scope.$input = $('input', scope.$target);
		scope.$results = $('<div class="locator__content"/>');

		scope.$target.append(scope.$results);

		scope.app = $.app.make(scope.uid, {
			target: scope.$results,
			view: 'locator.results',
			model: {
				id: conf.id,
				key: conf.key,
				fetch: conf.fetch &&
					typeof navigator.geolocation !== 'undefined',
				name: scope.$input[0].name || 'location'
			}
		});

		scope.$input.removeAttr('name');

		scope.$input[0].autocomplete = 'off';
		scope.$input[0].spellcheck = 'false';

		scope.$input[0].setAttribute('aria-autocomplete', 'none');

		var value = conf.value || scope.$input.data('value');

		if (value) {
			scope.$public.set(value);

			if (conf.fetch) {
				scope.query(false);
			}
		} else if (conf.default && ! window.crawler) {
			scope.setDefault();
		}

		scope.bindEvents();
	},

	/**
	 * Set the default location based on user data
	 */
	setDefault: function() {
		var scope = this,
			loc = LS.store.get('user_search');

		if (loc) {
			scope.setLocation(loc);
			scope.query(false);

			return;
		}

		$.observe('user', function(data) {
			loc = (data || {}).location;

			if (loc) {
				if ((loc.country || 'US') === 'US') {
					scope.setLocation(loc);
				}

				$.unobserve('*', {
					namespace: scope.uid
				});
			}

			scope.query(false);
		}, {
			init: true,
			namespace: scope.uid,
			recursive: true
		});
	},

	/**
	 * Set the default location value
	 *
	 * @param {Object} loc
	 */
	setLocation: function(loc) {
		var scope = this,
			$input = scope.$input;

		if (! $input.val() && ! scope.default) {
			var value = loc.name;

			if (loc.locality) {
				value = loc.locality + ', ' + loc.territory;
			}

			if (value) {
				scope.val = scope.default = value;

				$input.val(value);

				scope.app.$set('value', loc);
			}
		}
	},

	/**
	 * Bind result, input, and wrapper events
	 */
	bindEvents: function() {
		var scope = this,
			$input = scope.$input,
			cancel,
			down,
			blur,
			id,
			init,
			value;

		// Attach click events to result entries
		$.events.on('$locatorEntry', {
			click: function(e, el) {
				down = false;

				scope.select(el);
			},
			mousedown: function() {
				down = true;

				$(document).on('mouseup', function(e) {
					if (! scope.$target[0].contains(e.target)) {
						down = false;

						scope.collapse();
					}
				}, {
					namespace: scope.uid,
					once: true
				});
			}
		}, {
			delegate: scope.$results,
			namespace: scope.uid
		});

		// Bind primary result events
		$input.on({
			mousedown: function() {
				if (! scope.active) {
					$input.trigger('focus');
				}
			},
			focus: function(e, el) {
				if (blur) {
					blur = false;

					return;
				}

				id = el.id || '';
				value = el.value;

				// Suppress autocompletion
				el.id = 'new-search';
				el.autocomplete = 'new-password';

				scope.show();

				if (scope.$public.val() && scope.conf.type !== 'address') {
					el.value = '';
				}

				if (! init) {
					scope.process(null, scope.default ? '' : el.value);
				}

				init = true;

				$.exec(scope.conf.focused);
			},
			blur: function(e, el) {
				if (e.target === document.activeElement) {
					return;
				}

				el.id = id;
				el.autocomplete = 'off';

				if (! down) {
					if (! cancel) {
						scope.blur(el.value);
					}

					setTimeout(function() {
						scope.collapse();
					}, 100);
				}

				cancel = false;
			},
			keydown: function(e, el) {
				var key = e.keyCode || 13;

				cancel = false;

				if (key === 38 || key === 40) { // Arrow up or down
					e.preventDefault();
				} else if (key === 13) { // Enter
					cancel = true;

					if (scope.active) {
						e.preventDefault();
					}

					if (scope.pending) {
						scope.wait = true;
					} else if (scope.app.$get('results')) {
						scope.action();
					} else {
						cancel = false;
					}

					el.blur();
				} else if (key === 27) { // Escape
					scope.val = null;

					el.blur();
				} else if (key === 9) { // Tab
					scope.abort();

					if (scope.$active) {
						cancel = true;

						scope.set(scope.$active[0].dataset.index);
					}

					el.blur();
				} else if (key === 8) { // Backspace
					if (! el.value) {
						scope.$public.clear();
					}
				} else if ((e.ctrlKey || e.metaKey) && key !== 86) { // Control
					cancel = true;
				}
			},
			keyup: function(e, el) {
				if (! cancel) {
					scope.process(e.keyCode, el.value);
				}

				e.preventDefault();
				e.stopPropagation();
			}
		}, {
			namespace: scope.uid
		});

		$(window).on('blur', function() {
			if (scope.visible) {
				blur = true;

				scope.blur($input.val());

				scope.collapse();
			}
		}, {
			namespace: scope.uid
		});
	},

	/**
	 * Blur input
	 *
	 * @param {String} val
	 */
	blur: function(val) {
		var scope = this;

		if (scope.conf.type === 'address') {
			if (! val) {
				scope.$public.clear(true);
			}
		} else {
			if (val && scope.app.$get('results')) {
				scope.set(0);
			} else if (! val && scope.val) {
				scope.$input.val(scope.val);
			} else if (! val || val !== scope.val) {
				scope.$public.clear(true);
			}
		}
	},

	/**
	 * Collapse the visible result list
	 */
	collapse: function() {
		var scope = this;

		scope.active = scope.$active = scope.$entries = null;

		scope.abort();

		if (scope.visible) {
			$$('wrapper').off('click.' + scope.uid);

			if (scope.conf.collapse) {
				scope.conf.collapse(scope.$input.val());
			}
		}

		scope.hide();
	},

	/**
	 * Process the keyup event for evaluation
	 *
	 * @param {Number} key
	 * @param {String} val
	 */
	process: function(key, val) {
		var scope = this;

		scope.active = true;

		if (scope.$active) {
			scope.$active.removeClass('-is-active');
		}

		if (key === 38 || key === 40) { // Arrow up or down
			if (scope.$active) {
				var $active = scope.$active;

				$active = key === 40 ?
					$active.next() :
					$active.prev();

				if ($active.length) {
					scope.$active = $active;
				}
			} else if (
				key === 40 &&
				scope.app.$get('results')
			) {
				scope.$active = scope.$entries.first();
			} else {
				scope.$active = null;
			}

			if (scope.$active) {
				scope.$active[0].classList.add('-is-active');
			}
		} else if (val.length > 1 || ! val) {
			if (key !== 32) {
				scope.abort();
			}

			var fn = function() {
				scope.cancel();
				scope.query(val);
			};

			if (! scope.pending) {
				scope.state(true);
			}

			if (! val) {
				fn();

				return;
			}

			scope.pending = setTimeout(fn, 150);
		}
	},

	/**
	 * Cancel pending requests
	 */
	cancel: function() {
		var scope = this;

		clearTimeout(scope.pending);

		scope.pending = null;
	},

	/**
	 * Flush pending operations
	 */
	abort: function() {
		var scope = this;

		$.fetch.abort(scope.uid);

		scope.cancel();
		scope.state();
	},

	/**
	 * Query API for updated locator suggestions
	 *
	 * @param {String} value
	 */
	query: function(value) {
		var scope = this,
			type = scope.conf.type || 'all';

		scope.$active = null;

		if (value === false) {
			var nearby = scope.$get(type, []);

			if (nearby === true) {
				return;
			}

			if (nearby.length) {
				scope.populate(value, nearby);

				return;
			}

			scope.$set(type, true);
		} else {
			value = value.trim();

			if (value === scope.previous) {
				scope.show();

				return;
			}
		}

		scope.previous = value;

		var loc = scope.$public.val() || LS.user.location(),
			data = {
				limit: scope.getLimit()
			};

		if (value) {
			data.value = value;
		}

		if (loc) {
			if (loc.slug) {
				data.center = loc.slug;
			} else if (loc.center) {
				data.center = loc.center;
			} else if (loc.latitude) {
				data.center = [
					loc.longitude,
					loc.latitude
				];
			}
		}

		var center = scope.conf.center,
			options = {
				namespace: scope.uid,
				data: data,
				success: function(data) {
					if (! data.features.length && value.indexOf(',') > -1) {
						scope.queryAddress(type, value, data);

						return;
					}

					scope.transform(type, value, data.features);

					if (! value) {
						scope.stash = data.features;
					}
				}
			},
			key = JSON.stringify(options.data);

		if (center) {
			data.center = typeof center === 'function' ?
				center(data) : center;
		}

		if (scope.conf.type === 'address' || (
			scope.conf.address && value && (
				value.match(/^\d{1,4} *[a-z]/i) ||
				value.match(/ (ave(nue)?|dr(ive)?|r(oa)?d|st(reet)?)\.?( |$)/i) ||
				value.match(/(highway|hwy)\.? /i)
			)
		)) {
			scope.queryAddress(type, value, data);

			return;
		}

		if (scope.conf.type) {
			options.data.type = $.toArray(scope.conf.type);
		}

		if (! value && scope.stash) {
			scope.populate(value, scope.stash);

			return;
		}

		LS.api.get('locations/autocomplete', options);
	},

	/**
	 * Query API for address suggestions
	 *
	 * @param {String} key
	 * @param {String} value
	 * @param {Object} data
	 */
	queryAddress: function(key, value, data) {
		var scope = this,
			query = {
				access_token: $.get('global.mapboxKey'), // eslint-disable-line
				country: 'US',
				limit: data.limit,
				types: 'address'
			};

		if (Array.isArray(data.center)) {
			query.proximity = data.center.join(',');
		} else {
			var loc = LS.user.location();

			if (loc) {
				query.proximity = loc.longitude + ',' + loc.latitude;
			}
		}

		$.fetch.request({
			root: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
			url: value + '.json',
			json: true,
			namespace: scope.uid,
			data: query,
			success: function(data) {
				scope.transform(key, value, data.features.map(function(feature) {
					var name = (feature.address ? feature.address + ' ' : '') + feature.text;

					return {
						name: name,
						type: 'address',
						slug: scope.conf.key === 'name' ?
							name : feature.center.join('+'),
						center: feature.center,
						parent: {
							name: feature.place_name.split(',')
								.splice(1, 2)
								.join(',')
						},
						feature: feature
					};
				}));
			}
		});
	},

	/**
	 * Get result limit
	 *
	 * @returns {Number}
	 */
	getLimit: function() {
		return this.conf.limit || 5;
	},

	/**
	 * Transform query results
	 *
	 * @param {String} key
	 * @param {String} value
	 * @param {Array} features
	 */
	transform: function(key, value, features) {
		var scope = this,
			match;

		if (! scope.app) {
			return;
		}

		if (value) {
			var keywords = value.split(/[ ,]/)
				.map(function(keyword) {
					return keyword.trim();
				})
				.filter(function(keyword) {
					return keyword;
				});

			if (keywords.length > 1) {
				keywords.unshift(value.replace(/ /g, '[ ,]+'));
			}

			try {
				match = new RegExp('(' + keywords.join('|') + ')(\\b| ?(?!([^<]+)?<))', 'gi');
			} catch (e) {
				//
			}
		}

		features.forEach(function(el) {
			el.name += (el.label ? ' ' + el.label : '') +
				(el.suffix ? ', ' + el.suffix : '');

			el.heading = match ?
				el.name.replace(match, '<b>$&</b>')
					.replace(/<\/b> +<b>/g, ' ') :
				el.name;

			if (el.parent) {
				el.parent.heading = LS.location.heading(el.parent, true);
			}
		});

		scope.populate(value, features);

		if (! value) {
			scope.$set(key, features);
		}
	},

	/**
	 * Render locator options
	 *
	 * @param {String} value
	 * @param {Array} results
	 */
	populate: function(value, results) {
		var scope = this;

		scope.state();

		if (! scope.app) {
			return;
		}

		if (results.length) {
			scope.app.$merge({
				message: false,
				results: results.slice(0, scope.getLimit())
			});

			if (scope.active) {
				scope.show();

				$.exec(scope.conf.load);
			}
		} else if (value) {
			scope.visible = true;

			scope.$entries = null;

			scope.app.$merge({
				message: scope.active,
				results: null
			});
		}

		if (scope.wait) {
			scope.$input.trigger('keydown');

			delete scope.wait;
		}
	},

	/**
	 * Set update state
	 *
	 * @param {Boolean} [update=false]
	 */
	state: function(update) {
		var scope = this;

		if (update) {
			if (! scope.updating) {
				scope.updating = true;

				scope.$results[0].classList.add('-is-updating');
			}

			return;
		}

		scope.updating = false;

		scope.$results[0].classList.remove('-is-updating');
	},

	/**
	 * Display results
	 */
	show: function() {
		var scope = this;

		scope.$entries = $('$locatorEntry', scope.$results);

		if (! scope.visible) {
			scope.visible = true;

			$.exec(scope.conf.opened);

			scope.$target[0].classList.add('-is-active');
		}
	},

	/**
	 * Hide results
	 */
	hide: function() {
		var scope = this;

		if (scope.visible) {
			scope.visible = false;

			$.exec(scope.conf.closed);

			scope.$target.removeClass('-is-active');
		}
	},

	/**
	 * Process available context for action
	 */
	action: function() {
		var scope = this,
			$entries = scope.$entries;

		if (scope.$active) {
			scope.select(scope.$active[0]);
		} else if ($entries && $entries.length) {
			scope.select($entries[0]);
		} else {
			scope.collapse();
		}
	},

	/**
	 * Process locator result selection
	 *
	 * @param {$} el
	 */
	select: function(el) {
		var scope = this,
			index = el.dataset.index;

		if (! /\d/.test(index)) {
			if (index === 'fetch') {
				LS.common.transition();

				navigator.geolocation.getCurrentPosition(function(pos) {
					scope.set({
						type: 'address',
						slug: [
							pos.coords.longitude,
							pos.coords.latitude
						].join('+')
					}, true);

					LS.common.transition(true);
				}, function(e) {
					LS.common.transition(true);

					LS.util.warn(e.message);
				}, {
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 300000
				});
			}

			return;
		}

		scope.set(index, true);
	},

	/**
	 * Set location
	 *
	 * @param {Number|Object} value
	 * @param {Boolean} [trigger=false]
	 */
	set: function(value, trigger) {
		var scope = this;

		if (! isNaN(value)) {
			value = scope.app.$get('results.' + value);
		}

		scope.default = null;

		scope.app.$set('value', value);

		if (value) {
			scope.val = value.name;

			scope.$input.val(scope.val);
		}

		scope.collapse();

		if (value && trigger && scope.conf.changed) {
			scope.conf.changed(value);

			if (value.type !== 'address') {
				LS.store.set('user_search', value);
			}
		}
	}
});