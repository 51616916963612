Apt.fn.extend('properties', {
	/**
	 * Fetch properties
	 *
	 * @param {Object} options
	 * @param {Function} fn
	 */
	fetch: function(options, fn) {
		Apt.index.request({
			resource: 'listings',
			filters: $.extend({
				limit: 36,
				sort: '-promoted'
			}, options),
			transform: this.transform
		}, fn);
	},

	/**
	 * Transform server response for client
	 *
	 * @param {Object} result
	 * @param {Number} index
	 * @param {Number} [load=4]
	 */
	transform: function(result, index, load) {
		var center = result.context.center;

		if (result.image) {
			result.image.path = $.get('cdnUrl') + result.image.path;
		} else {
			result.image = {
				tile: true,
				path: 'https://tiles.stadiamaps.com/data/imagery/15/' +
					LS.util.getTileX(center[0]) + '/' + LS.util.getTileY(center[1]) + '.jpg'
			};
		}

		if (result.price) {
			if (result.format === 'sale') {
				result.schema = {
					price: result.price,
					image: result.image.path.replace('/small/', '/large/')
				};
			}

			result.price = LS.util.formatCurrency(result.price);
		}

		if (result.size) {
			result.size = LS.util.formatSize(result.size, true);
		}

		if (result.image) {
			result.image.cover = true;
			result.image.fetched = true;
			result.image.lazy = index >= (load || 4);
			result.image.position = 1;

			result.images = [
				result.image
			];

			result.current_image = 1; // eslint-disable-line

			delete result.image;
		}

		result.active = 'gallery';
	},

	/**
	 * Get base app model
	 *
	 * @param {Object} filters
	 * @param {Object} [loc]
	 * @param {Object} [data]
	 * @param {Boolean} [init=false]
	 */
	getModel: function(filters, loc, data, init) {
		var lease = filters.format === 'leases',
			empty = 'Reset the filters or explore ',
			reset = '';

		if (! loc && $.context) {
			loc = $.context('location');
		}

		if (loc && (
			filters.search ||
			filters.tag ||
			filters.type
		) && (init || (
			data.total_pages === 1 || (
				data.total_pages > 1 &&
				data.current_page === data.total_pages
			)
		))) {
			var path = lease ?
					'/leases' : '/properties',
				ancestor = '',
				message;

			if (loc.slug) {
				path += '/' + loc.slug;

				if (loc.ancestors && [
					'locality',
					'region',
					'subterritory'
				].indexOf(loc.type) > -1) {
					var root = data.root,
						territory;

					if (! root && $.context) {
						root = $.context('root');
					}

					loc.ancestors.forEach(function(item) {
						if (item.type === 'territory') {
							territory = item;
						}
					});

					if (root && territory) {
						ancestor = ' and <a href="' + root[1] + '/' + territory.slug + '" class="-plain">' + root[0] + ' in ' + territory.name + '</a>';
					}
				}
			}

			message = '<a href="' + path + '" class="-plain">' +
				'land for ' + (lease ? 'lease' : 'sale') + ' in ' +
				LS.location.heading(loc, true) + '</a>' +
				ancestor;

			empty += message;

			reset = {
				after: ! init &&
					data.current_page === data.total_pages,
				message: message
			};
		} else {
			empty += 'nearby listings on the map';
		}

		return {
			ad: [4, 14, 22, 32, 40, 50],
			empty: data.total ?
				'' : empty + '.',
			format: lease ?
				'leases' : 'sales',
			label: 'property',
			reset: reset
		};
	},

	/**
	 * Get sorting options
	 *
	 * @returns {Object}
	 */
	getSorting: function() {
		return {
			'-promoted': 'For you',
			'-newest': 'Newest',
			'-price': 'Price: high to low',
			'price': 'Price: low to high',
			'-size': 'Size: high to low',
			'size': 'Size: low to high',
			'-updated': 'Updated'
		};
	}
}, {
	/**
	 * Create the property results application
	 */
	initApp: function() {
		var scope = this,
			pub = scope.$public,
			$results = $$('results'),
			app = $results.data('id'),
			current = null,
			exclude = [],
			events,
			ads;

		scope.index = Apt.fn.index().init({
			app: app,
			init: false,
			paginate: true,
			path: scope.section,
			reload: true,
			resource: 'listings',
			scrollTop: false,
			sorting: pub.getSorting(),
			target: $results,
			transform: scope.$public.transform,
			value: $results.data('value'),
			view: 'properties.results',
			params: {
				limit: 50
			},
			modify: function(data) {
				$.extend(data, pub.getModel(
					LS.filters.get(),
					data.location || $.context('location'),
					data,
					! events && ! $.history.pending()
				));
			},
			translate: function(filters) {
				var hidden = LS.user.get('hidden');

				if (hidden && hidden.length) {
					filters.user = LS.user.get('id');
				}

				if (! events && scope.sync && scope.sync.check(true)) {
					filters.bounds = scope.sync.getBounds();
				} else if (LS.filters.get('buffer') !== '0') {
					filters.buffer = true;
				}
			},
			send: function(xhr, conf) {
				var data = conf.data;

				document.documentElement.scrollTop = 0;

				if (! data.center) {
					var center = LS.location.center() || LS.user.center();

					if (center) {
						data.center = center;
					}
				}

				if (! data.page || data.page === 1 && ! data.bounds) {
					data.attach = [
						'render'
					];
				}
			},
			updated: function(data, init) {
				var loc = data.location,
					relocated;

				if (loc) {
					relocated = loc.slug !== current;

					loc.heading = LS.location.heading(loc);
				}

				if (! init) {
					scope.updateApp(data);

					scope.filters.refresh(relocated ? loc : false);

					if (relocated && scope.map) {
						scope.map.removeDraw();
					}

					if (events) {
						events.update();
					}

					if (data.current_page === 1) {
						LS.util.destroy(scope, 'meta');

						$$('propertiesFooter').remove();
					}
				}

				scope.updateTracking(data.results);

				if (scope.fallback) {
					scope.fallback.$destroy();
				}

				if (data.current_page === 1) {
					exclude = [];
				}

				exclude = exclude.concat(
					LS.util.pluck(data.results, 'id')
				);

				if (! data.total || data.current_page === data.total_pages) {
					scope.fallbackApp(exclude);
				}

				if (init || relocated) {
					scope.loadAgent(loc, data.results[0]);
				}

				if (relocated) {
					current = loc ?
						loc.slug : null;

					$$('propertiesLocation').text(loc.heading);
				}

				if (ads) {
					if (! scope.sync || ! scope.sync.check(true)) {
						ads.load();
					}
				} else if (data.results.length) {
					LS.util.load('ads', function() {
						ads = Apt.fn.ads().init({
							margin: function() {
								return 800;
							},
							target: function() {
								return scope.getTarget();
							},
							units: function() {
								return $('[data-unit]', $results);
							}
						});
					});
				}
			},
			unload: function() {
				if (events) {
					events.unload();
				}

				if (ads) {
					ads.unload();
				}
			},
			destroy: function() {
				if (events) {
					events.$destroy();

					Apt.panel.unload();
				}

				if (ads) {
					ads.$destroy();

					ads = null;
				}
			}
		}, function() {
			Apt['properties.panel'].init({
				map: function() {
					return scope.map;
				},
				sync: scope.sync
			});

			events = Apt.fn['properties.events']()
				.init({
					app: app,
					delegate: '$results',
					map: function() {
						return scope.map;
					},
					createMap: function(options) {
						if (! scope.map) {
							scope.loadMap(options);
						}
					},
					sync: scope.sync
				});

			if (location.hash === '#inactive') {
				LS.util.flash('Inactive listing — browse nearby');
			}

			$$('saveSearch').on('click', function() {
				LS.util.load('save', function() {
					Apt.save.init();
				}, true, false);
			}, {
				namespace: 'properties'
			});

			$results[0].dataset.value = '';
		});

		if (scope.sync) {
			scope.sync.$private.conf.index = scope.index;
		}
	},

	/**
	 * Load promoted agent for location
	 *
	 * @param {Object} loc
	 * @param {Object} [result]
	 */
	loadAgent: function(loc, result) {
		var $agent = $$('propertiesAgent');

		if (result && (
			! loc ||
			loc.type === 'country' ||
			loc.type === 'territory'
		)) {
			loc = result.location.subterritory;
		}

		if (! loc) {
			$agent.empty();

			return;
		}

		LS.util.scrolled($agent, 'properties', function() {
			LS.profiles.feature($agent, LS.util.sanitize({
				location: loc.slug,
				price: (LS.filters.get('price') || {}).max,
				size: (LS.filters.get('size') || {}).max,
				specialties: LS.filters.get('type')
			}));
		}, {
			margin: 920
		});
	},

	/**
	 * Update the properties view
	 *
	 * @param {Object} data
	 */
	updateApp: function(data) {
		var scope = this;

		if (data.title) {
			var context = {
				location: data.location,
				targeting: null
			};

			document.title = data.title + ' - LandSearch';

			$$('propertiesTitle').text(data.heading);

			$.merge('context', context);

			$('.js-context').data('value', context);

			if (scope.map) {
				if (LS.filters.get('location')) {
					scope.map.position();
				} else {
					var position = scope.map.parsePosition();

					if (position) {
						scope.map.center(position.center, {
							animate: true,
							zoom: position.zoom
						});
					}
				}
			}
		}

		if (scope.map) {
			scope.clearMap(scope.map, true);
		}
	},

	/**
	 * Update the properties analytics
	 *
	 * @param {Object} data
	 * @param {Boolean} [track=true]
	 */
	updateTracking: function(data, track) {
		if (track === false || ! data || ! data.length) {
			return;
		}

		Object.keys(data).forEach(function(key) {
			LS.analytics.track({
				action: 'preview',
				target: 'listing',
				id: data[key].id,
				category: 'entry',
				segment: 'results'
			}, {
				key: data[key].context.key,
				source: data[key].context.source,
				send: false
			});
		});

		LS.analytics.send();
	},

	/**
	 * Display fallback results
	 *
	 * @param {Array} exclude
	 */
	fallbackApp: function(exclude) {
		var scope = this,
			params = LS.filters.get(),
			$element = $($.view.render('properties.fallback', {
				empty: ! exclude.length,
				location: $.context('location.heading')
			}));

		if (! params.location) {
			return;
		}

		delete params.location;

		params.exclude = exclude;
		params.limit = 48;

		$$('results').append($element);

		scope.fallback = Apt.fn['properties.relevant']().init({
			params: params,
			map: function() {
				return scope.map;
			},
			resource: 'listings',
			standalone: false,
			createMap: function(options) {
				if (! scope.map) {
					scope.loadMap(options);
				}

				return scope.map;
			},
			updated: function(data) {
				if (! data.results.length) {
					$$('propertiesFallback').hide();

					return;
				}

				$element.show();
			},
			unload: function() {
				scope.fallback = null;
			}
		}, {
			position: [2, 12, 20, 30, 38, 48],
			target: function() {
				return scope.getTarget();
			}
		});
	},

	/**
	 * Refresh properties view
	 */
	refresh: function() {
		var scope = this,
			filters = LS.filters.get();

		if (scope.sync && scope.sync.check(true)) {
			if (filters.location) {
				scope.sync.disable(false);
			} else {
				scope.sync.$private.refresh();
				scope.map.update();

				return;
			}
		}

		if (scope.map) {
			scope.map.update();
		}

		scope.index.update({
			push: false,
			scrollTop: 0
		}, filters);
	}
});