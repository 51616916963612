Apt.fn.make('index', {
	/**
	 * Initialize module
	 *
	 * @param {Object} options
	 * @param {Function} [fn]
	 * @returns {Object}
	 */
	init: function(options, fn) {
		var scope = this,
			priv = scope.$private,
			conf = $.extend({
				filters: {},
				history: false,
				init: true,
				model: {},
				paginate: false,
				params: {},
				push: true,
				sorting: false,
				target: '$results',
				title: document.title.split('-')[0]
			}, options);

		scope.uid = LS.util.uid();

		if (! conf.app) {
			conf.app = scope.uid;
		}

		if (! conf.static) {
			$.extend(conf.filters, LS.filters.get());
		}

		conf.$target = $(conf.target);

		if (conf.paginate) {
			conf.params.paginate = true;
		}

		conf.resource = '/' + conf.resource;

		conf.path = conf.path ?
			conf.path : conf.resource;

		scope.conf = conf;

		priv.sorted = '';

		priv.initSorting(conf);
		priv.load(fn);
		priv.initControls(conf);

		return scope;
	},

	/**
	 * Request filtered data from index endpoint
	 *
	 * @param {Object} options
	 * @param {Object} [filters]
	 */
	update: function(options, filters) {
		var scope = this,
			priv = scope.$private;

		if (filters) {
			scope.conf.filters = filters;
		} else {
			filters = {};
		}

		options = $.extend({}, scope.conf, options);

		priv.go({
			action: options ?
				options.action : 'replace',
			path: options.resource,
			push: options ?
				options.push : false,
			scrollTop: options.scrollTop
		}, priv.prep(filters));
	},

	/**
	 * Request filtered data from index endpoint
	 *
	 * @param {Object} [filters]
	 * @param {String} [path]
	 * @param {Object} [options]
	 */
	filter: function(filters, path, options) {
		var scope = this,
			priv = scope.$private,
			conf = scope.conf,
			title;

		filters = $.extend(conf.filters, filters);

		if (conf.build) {
			conf.build(filters);
		}

		if (! path) {
			var criteria = filters;

			if (filters.sort === priv.sorted) {
				criteria = $.copy(filters);

				delete criteria.sort;
			}

			path = LS.filters.build(conf.path, criteria);
		}

		title = priv.getPageTitle();

		if (conf.reload) {
			priv.xhr = $.history.go({
				path: path,
				action: false,
				scrollEarly: true,
				scrollTop: false,
				title: title
			});

			return;
		}

		priv.go($.extend({
			path: conf.resource,
			push: conf.push ?
				path : false,
			title: title
		}, options), priv.prep(filters, ! conf.static));
	},

	/**
	 * Make initial data request
	 *
	 * @param {Object} options
	 * @param {Function} [fn]
	 */
	request: function(options, fn) {
		var scope = this,
			priv = scope.$private,
			conf = scope.conf,
			filters = options.filters || {};

		if (conf) {
			$.exec(conf.translate, {
				args: [filters, options]
			});
		}

		priv.xhr = LS.api.get(options.resource, {
			auth: conf && conf.auth,
			data: filters ?
				LS.util.sanitize(filters) : null,
			send: conf && conf.send,
			flash: ! conf || conf.flash !== false,
			success: function(obj) {
				priv.fetched = true;

				priv.transform(options, obj);

				if (fn) {
					fn(obj);
				}
			},
			complete: function() {
				priv.xhr = null;

				if (conf) {
					conf.$target.removeClass('-is-loading');
				}
			}
		});
	},

	/**
	 * Abort existing index request
	 */
	abort: function() {
		var priv = this.$private;

		if (priv.xhr && priv.xhr.abort) {
			priv.xhr.abort();

			priv.xhr = null
		}
	},

	/**
	 * Destroy module
	 *
	 * @private
	 */
	_destruct: function() {
		var scope = this;

		scope.abort();

		$.events.reset(scope.uid);

		$.exec(scope.conf.unload, {
			args: true
		});

		LS.util.destroy(scope, 'app');

		$.exec(scope.conf.destroy);
	}
}, {
	/**
	 * Prepare filters for processing
	 *
	 * @param {Object} filters
	 * @param {Boolean} [dynamic=false]
	 * @returns {Object} data
	 */
	prep: function(filters, dynamic) {
		var scope = this,
			conf = scope.$public.conf || {};

		if (dynamic) {
			filters = $.extend(LS.filters.get(), filters);
		}

		if (! filters.sort && scope.sorted) {
			filters.sort = scope.sorted;
		}

		return $.extend({}, filters, conf.params);
	},

	/**
	 * Load initial index data
	 *
	 * @param {Function} [fn]
	 */
	load: function(fn) {
		var scope = this,
			pub = scope.$public,
			conf = pub.conf,
			model = {
				label: 'result',
				base: LS.filters.base()
			};

		if (conf.sorting) {
			model.sorting = conf.sorting;
		}

		conf.model = $.extend({}, model, conf.model);

		if (conf.value) {
			scope.transform(conf, conf.value);
			scope.populate(conf.value, fn);

			scope.fetched = true;

			return;
		}

		pub.request({
			filters: scope.prep(conf.filters),
			resource: conf.resource,
			send: conf.send,
			transform: conf.transform
		}, function(data) {
			scope.populate(data, fn);
		});
	},

	/**
	 * Populate model data
	 *
	 * @param {Object} data
	 * @param {Function} [fn]
	 */
	populate: function(data, fn) {
		var scope = this,
			pub = scope.$public,
			conf = pub.conf;

		data = $.extend({}, conf.model, data);

		if (conf.reset) {
			data.reset = LS.filters.count();
		}

		pub.app = $.app.make(conf.app, {
			model: data,
			target: conf.$target,
			view: conf.view,
			init: conf.init
		});

		if (conf.sorting) {
			scope.bindSorting();
		}

		if (conf.paginate) {
			scope.paginate();
		}

		if (fn) {
			fn(data);
		}

		scope.update(data, true);
	},

	/**
	 * Navigate index to new path
	 *
	 * @param {Object} options
	 * @param {Object} [data]
	 */
	go: function(options, data) {
		var scope = this,
			pub = scope.$public,
			conf = pub.conf,
			updateClass = '-is-updating';

		pub.abort();

		conf.$target.addClass(updateClass);

		if (scope.fetched) {
			$.exec(conf.unload);
		}

		data = LS.util.sanitize(data);

		$.exec(conf.translate, {
			args: [data, options]
		});

		scope.xhr = $.history.go($.extend(true, {
			force: true,
			push: conf.push,
			run: false,
			scrollEarly: true,
			scrollTop: function(a, obj) {
				return scope.scrollTop(conf, obj);
			},
			scrollTarget: function() {
				return scope.scrollTarget(conf);
			},
			begin: function(conf) {
				if (! pub.app) {
					$.history.go(conf.path);

					return false;
				}
			},
			replace: function(obj) {
				pub.app.$pause();

				pub.app.$set('base', LS.filters.base());

				if (obj.results) {
					if (! scope.diff(obj)) {
						return;
					}

					scope.transform(conf, obj);

					pub.app.$merge(obj);
				}

				pub.app.$resume(true);

				return false;
			},
			request: LS.api.extend({
				auth: conf.auth,
				data: data,
				send: conf.send,
				success: function(obj) {
					scope.update(obj);
				},
				complete: function() {
					scope.xhr = null;

					conf.$target.removeClass(updateClass);
				}
			})
		}, options));
	},

	/**
	 * Compare new data to cache
	 *
	 * @param {Object} data
	 * @returns {boolean}
	 */
	diff: function(data) {
		var scope = this;

		return ! (
			data.total === scope.total &&
			$.equals(scope.active, LS.util.pluck(data.results, 'id'))
		);
	},

	/**
	 * Run update logic after data render
	 *
	 * @param {Object} data
	 * @param {Boolean} [init=false]
	 */
	update: function(data, init) {
		var conf = this.$public.conf;

		if (conf.updated) {
			conf.updated(data, init);
		}
	},

	/**
	 * Run applicable result transformation
	 *
	 * @param {Object} conf
	 * @param {Object} data
	 */
	transform: function(conf, data) {
		if (conf.modify) {
			conf.modify(data, conf);
		}

		if (
			! conf.transform ||
			! data.results ||
			! data.results.length
		) {
			return;
		}

		data.results.forEach(function(result, i) {
			conf.transform(result, i, conf);
		});
	}
});