LS.profiles = {
	/**
	 * Render featured agent
	 *
	 * @param {$} $target
	 * @param {Object} criteria
	 */
	feature: function($target, criteria) {
		LS.api.get('profiles/featured', {
			data: {
				type: 'agent',
				criteria: criteria
			},
			flash: false,
			success: function(data) {
				if (! data.id) {
					$target.empty();

					return;
				}

				$target.html(
					$.view.render('profiles._featured', data)
				);

				LS.analytics.track({
					action: 'preview',
					target: 'profile',
					id: data.id,
					category: 'entry',
					segment: 'featured'
				});
			}
		});
	}
};