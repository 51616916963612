Apt.fn.extend('maps', {}, {
	/**
	 * Get listing groups
	 *
	 * @param {Object} source
	 * @param {Number} [id]
	 * @param {Function} [fn]
	 */
	getGroup: function(source, id, fn) {
		var scope = this,
			relation = scope.relation[source];

		scope.groupFn = fn;

		if (relation.groups) {
			if (id && relation.groups !== true) {
				var match = relation.groups[id];

				fn(match ? match[0] : 0);
			}

			return;
		}

		relation.groups = true;

		var group = source;

		LS.api.get(scope.conf.countPath || 'listings/count', {
			auth: scope.conf.auth,
			namespace: 'map',
			data: LS.util.sanitize(
				$.extend(scope.conf.countData || LS.filters.get(null), {
					group: group,
					bounds: '',
					center: '',
					location: '',
					page: '',
					sort: ''
				})
			),
			success: function(data) {
				if (! scope.active) {
					return;
				}

				if (
					group === 'subterritories' &&
					! scope.conf.showPoints
				) {
					var pub = scope.$public,
						zoom,
						fn = function() {
							if (scope.map.getZoom() < 9) {
								scope.addGroup(
									scope.transformGroup(data)
								);

								pub.unbounce(zoom);
							}
						};

					zoom = pub.debounce('zoom', fn);

					pub.ready(function() {
						fn();
					});
				}

				relation.groups = data;

				if (id && scope.groupFn) {
					var match = relation.groups[id];

					scope.groupFn(match ? match[0] : 0);
				}
			}
		});
	},

	/**
	 * Transform group data
	 *
	 * @param {Object} data
	 * @returns {Array}
	 */
	transformGroup: function(data) {
		var scope = this;

		scope.count = Object.keys(data).length;
		scope.sum = 0;
		scope.max = 0;

		var groups = Object.keys(data)
			.map(function(key) {
				var loc = data[key],
					count = loc[0];

				scope.sum += count;

				if (count > scope.max) {
					scope.max = count;
				}

				return {
					type: 'Feature',
					id: key,
					properties: {
						count: count
					},
					geometry: {
						type: 'Point',
						coordinates: loc[1]
					}
				};
			});

		scope.avg = scope.sum / scope.count;

		return groups;
	},

	/**
	 * Add group chropleth
	 *
	 * @param {Array} features
	 */
	addGroup: function(features) {
		var scope = this;

		if (! scope.active) {
			return;
		}

		scope.addGroupSources('group', features);
		scope.addGroupLayers('group');
	},

	/**
	 * Add group sources
	 *
	 * @param {String} name
	 * @param {Array} features
	 */
	addGroupSources: function(name, features) {
		var scope = this,
			source = scope.map.getSource(name),
			data = {
				type: 'FeatureCollection',
				features: features
			};

		if (source) {
			source.setData(data);
			scope.updateGroupDensity();

			return;
		}

		scope.addSource(name, {
			type: 'geojson',
			data: data,
			cluster: true,
			clusterMaxZoom: 9,
			clusterRadius: 14,
			clusterProperties: {
				max: ['max', ['get', 'count']],
				sum: ['+', ['get', 'count']]
			}
		});
	},

	/**
	 * Add group layers
	 *
	 * @param {String} name
	 */
	addGroupLayers: function(name) {
		var scope = this;

		scope.addLayer({
			id: name + 'Labels',
			type: 'symbol',
			source: name,
			minzoom: 6.1,
			maxzoom: 9,
			layout: {
				'text-allow-overlap': true,
				'text-field': [
					'number-format', [
						'case',
						['has', 'sum'], ['get', 'sum'],
						['get', 'count']
					], {
						'max-fraction-digits': 0
					}
				],
				'text-font': [
					'averta-semi'
				],
				'text-ignore-placement': true,
				'text-offset': [0, 0],
				'text-padding': 14,
				'text-size': 14
			},
			paint: {
				'text-color': '#f2f9f5',
				'text-opacity': [
					'interpolate', ['linear'], ['zoom'],
					6.1, 0,
					6.3, 0.8
				]
			}
		});

		scope.addLayer({
			id: name,
			type: 'circle',
			source: name,
			maxzoom: 9,
			layout: {
				'circle-sort-key': [
					'case',
					['has', 'sum'], ['get', 'sum'],
					['get', 'count']
				]
			},
			paint: {
				'circle-color': [
					'case', ['boolean', ['feature-state', 'hover'], false],
					'#1e4d35',
					'#22563c'
				],
				'circle-opacity': [
					'interpolate', ['linear'], ['zoom'],
					2, 0.3,
					6, 0.5,
					6.2, 1
				]
			}
		}, scope.map.getZoom() >= 6.2 ?
			name + 'Labels' : 'parcels'
		);

		scope.updateGroupDensity();

		scope.bindGroup();
	},

	/**
	 * Set group circle radius
	 */
	updateGroupDensity: function() {
		var scope = this;

		if (! scope.map.getLayer('group')) {
			return;
		}

		scope.map.setPaintProperty('group', 'circle-radius', [
			'interpolate', ['linear'], ['zoom'],
			5.6, [
				'interpolate', ['linear'], [
					'case',
					['has', 'sum'], ['get', 'sum'],
					['get', 'count']
				],
				1, 3,
				9, 5,
				Math.max(Math.min((scope.max / 2), scope.avg), 10), 8,
				Math.max(scope.max, 11), 16
			],
			6.2, [
				'interpolate', ['linear'], [
					'case',
					['has', 'sum'], ['get', 'sum'],
					['get', 'count']
				],
				9, 14,
				100, 18,
				500, 22,
				1000, 26,
				3000, 30
			]
		], {
			validate: false
		});
	},

	/**
	 * Bind group layer events
	 */
	bindGroup: function() {
		var scope = this,
			pub = scope.$public,
			name = 'group';

		pub.bind(name, 'click', function(e) {
			if (scope.map.getZoom() < 6.2) {
				return;
			}

			pub.center(e.lngLat, {
				animate: true,
				zoom: 9
			});
		});

		pub.bind(name, 'mouseenter', function(e) {
			scope.setGroupState(e.features[0]);
		});

		pub.bind(name, 'mousemove', function(e) {
			scope.setGroupState(
				scope.map.queryRenderedFeatures(e.point)[0]
			);
		});

		pub.bind(name, 'mouseleave', function() {
			scope.dropGroupState();
		});
	},

	/**
	 * Set the group layer feature state
	 *
	 * @param {Object} feature
	 */
	setGroupState: function(feature) {
		var scope = this;

		if ($.supported('touch') || this.map.getZoom() < 6.2) {
			return;
		}

		if (scope.hoverGroup) {
			if (feature === scope.hoverGroup) {
				return;
			}

			scope.dropGroupState(feature);
		}

		scope.hoverGroup = feature;

		scope.map.setFeatureState(feature, {
			hover: true
		});
	},

	/**
	 * Drop the group layer feature state
	 */
	dropGroupState: function() {
		var scope = this;

		if (scope.hoverGroup) {
			scope.map.setFeatureState(scope.hoverGroup, {
				hover: false
			});
		}

		scope.hoverGroup = null;
	}
});