Apt.fn.extend('maps', {}, {
	/**
	 * Initialize element model
	 */
	initModel: function() {
		var scope = this,
			conf = scope.conf;

		if (conf.basic) {
			scope.$parent[0].classList.add('-basic');
		}

		scope.$overlay = $('<div class="map-overlay"/>');
		scope.$overlay.appendTo(scope.$parent);

		scope.model = $.app.make('map-' + scope.uid, {
			target: scope.$overlay,
			view: 'maps.elements',
			model: $.extend({
				bar: ! conf.single && ! conf.basic,
				basic: conf.basic,
				controls: ! conf.basic,
				detail: conf.hideZoomMessage,
				detailed: scope.map.getZoom() >= 9,
				draw: !! scope.conf.onDraw,
				preview: conf.preview,
				single: conf.single,
				style: conf.style,
				tab: 'layer'
			}, conf.model)
		});

		scope.$public.ready(function() {
			LS.util.idle([
				scope.initReset,
				scope.initZoom,
				scope.initPitch,
				scope.initResize,
				scope.initLabel,
				scope.initInspect,
				scope.bindOptions
			], scope);
		});
	},

	/**
	 * Initialize inspector
	 */
	initInspect: function() {
		var scope = this;

		$.events.on('$mapInspect', 'change', function(e, el) {
			if (el.checked) {
				scope.loadInspect();
			} else if (scope.inspect) {
				scope.inspect.$destroy();
			}
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});
	},

	/**
	 * Load inspector
	 *
	 * @param {Object} [e]
	 */
	loadInspect: function(e) {
		var scope = this;

		LS.util.load('inspect', function() {
			if (scope.inspect) {
				scope.inspect.$destroy();
			}

			scope.inspect = Apt.fn.inspect()
				.init(scope, e);

			LS.analytics.interact('map', 'inspect');
		});
	},

	/**
	 * Register map styling control functionality
	 */
	bindOptions: function() {
		var scope = this,
			pub = scope.$public;

		$.events.on('$mapOptionsToggle', 'mousedown', function() {
			scope.model.$get('showOptions') ?
				scope.removeOptions() :
				scope.addOptions();
		}, {
			delegate: scope.$overlay,
			namespace: scope.uid
		});

		if ($.screen.size() > 4) {
			pub.bind('map', 'contextmenu', function(e) {
				scope.loadInspect(e);
			});
		}

		if (scope.conf.onDraw) {
			var geometry = LS.filters.get('geometry');

			$$('mapDraw').on('mousedown', function() {
				var draw = scope.draw;

				if (! draw) {
					scope.setState();
				}

				LS.util.load('draw', function() {
					if (draw) {
						pub.removeDraw(true);

						scope.model.$drop('message');
					} else {
						scope.draw = Apt.fn.draw()
							.init(scope.$public, function(coordinates) {
								scope.conf.onDraw(coordinates);

								scope.model.$drop('message');
							}, LS.filters.get('geometry'));

						scope.model.$set('drawing', true);

						scope.setState(true);

						scope.model.$set('message', 'Draw your search area');

						LS.analytics.interact('map', 'draw');
					}
				}, true, false);
			}, {
				init: !! geometry,
				namespace: scope.uid
			});
		}
	},

	/**
	 * Show controls
	 */
	addOptions: function() {
		var scope = this,
			pub = scope.$public;

		scope.$public.hideTooltip();

		scope.model.$set('showOptions', true);

		scope.tabs = Apt.fn.tab()
			.init(scope.$overlay, function(id) {
				scope.model.$set('tab', id);
			});

		pub.bind('map', 'click', function() {
			scope.removeOptions();
		}, true);

		pub.bind('map', 'move', function() {
			scope.removeOptions();
		}, true);
	},

	/**
	 * Hide controls
	 */
	removeOptions: function() {
		var scope = this;

		if (! scope.active) {
			return;
		}

		if (scope.tabs) {
			scope.tabs.$destroy();

			scope.tabs = null;
		}

		scope.model.$drop('showOptions');
	}
});